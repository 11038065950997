import React, { useState , useEffect} from "react"
import { Link, useParams } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
/* import userAvatar from "../assets/img/img1.jpg";
import notification from "../data/Notification"; */
import NavBarMenu from "../components/NavBarMenu";
import styled from "styled-components";
 import NavbarPrimaryColor from "../components/NavbarPrimaryColor";
import {MyEstadoGlobalContext} from './MyEstadoGlobalContext';
import background from "./img/static/container-media@1x.png";
import axios from "axios";

export default function Header( props) {

  const { onSkin,placeholder,setIdq,setOrigenIn, setPantalla4} = props;
  //const [pantalla4b, setPantalla4b] = useState("");
  const { chartId } = useParams();
  const [shouldSearch, setShouldSearch] = useState(false); 
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  }

  //  useEffect(() => {  
  //  }, []); // Only re-run the effect if count changes

   const {mostrarWSE, setMostrarWSE} = React.useContext(MyEstadoGlobalContext);
   const {mostrarBD, setMostrarBD} = React.useContext(MyEstadoGlobalContext);
   const {mostrarProyectos, setMostrarProyectos} = React.useContext(MyEstadoGlobalContext);
   const {mostrarPI, setMostrarPI} = React.useContext(MyEstadoGlobalContext);
   const {mostrarHome, setMostrarHome} = React.useContext(MyEstadoGlobalContext);
   const {mostrarAyuda, setMostrarAyuda} = React.useContext(MyEstadoGlobalContext);
 
 

   const [pantalla2, SetPantalla2] = useState("random")
    //console.log("busqueda 0: "+ busqueda)  
   const [query, setQuery] = React.useState("");

   const [bandera, setBandera] = React.useState(false);

  // const ClickSearch = (e) => {
  //   SetPantalla({...pantalla, busqueda: e})

  const ClickBoton = (e) => {

    //SetPantalla2({...pantalla2, busqueda: e})
    setPantalla4(e);
    setOrigenIn("SE")
    //SetPantalla2({...pantalla2, busqueda: e})
    console.log("EN Header ")
    console.log(e)
    setQuery(e)
    setMostrarWSE(true);
    setMostrarBD(false);
    //setBandera(true);
    //setMostrarPI(false)
   // setMostrarProyectos(false)
   };

   useEffect(() => {
    //setOrigenIn(origenIn);
   // origenIn = 'Database' 
  //  setOrigenIn('Database')
  setMostrarBD(false)
  //setMostrarPI(false)
  //setMostrarProyectos(false)
 // alert("En usefect DB Header")
  }, [bandera,setMostrarBD]);


  useEffect(() => {
    const fetchChartData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}charts/mostrar/${chartId}`);
            console.log("DATOS TRAIDOS DESDE EL HEADER", response.data);

            if (response.data.chart && response.data.chart.cText) {
                const chartText = response.data.chart.cText;
                setQuery(chartText); // Actualiza `query` con el texto
                console.log("Nuevo query establecido:", chartText);

                // Solo activa la búsqueda si el texto fue obtenido correctamente
                setShouldSearch(true);
            } else {
                console.warn("El gráfico no tiene cText o datos válidos.");
            }
        } catch (error) {
            console.error("Error al obtener los datos del gráfico:", error);
        }
    };

    if (chartId) {
        fetchChartData(); // Llama a la API si el ID existe
    }
}, [chartId]);


useEffect(() => {
  if (shouldSearch && query) {
      console.log("Ejecutando búsqueda automáticamente con query:", query);

      // Agrega un retraso corto para asegurar que todos los estados estén actualizados
      setTimeout(() => {
          ClickBoton(query); // Llama a ClickBoton con el valor actualizado
          setShouldSearch(false); // Resetea el estado para evitar ejecuciones múltiples
      }, 100); // 100 ms de retraso
  }
}, [shouldSearch, query]);




   const ClickSetChart = (e) => {
  //  SetPantalla2({...pantalla2, busqueda: 'navmenu',chart: e})
    //SetPantalla(e);
    alert("ClickSetChart"+e)
    setIdq(e);
    // setOrigenIn("SE")
     //SetPantalla2({...pantalla2, busqueda: e})
     console.log("EN subheader 1 ")
     console.log(e)
     setMostrarWSE(true);
     setMostrarBD(false);

  };

  const skinMode = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    if (skin === "dark") {
      HTMLTag.setAttribute("data-skin", skin);
      localStorage.setItem('skin-mode', skin);

      onSkin(skin);

    }
    // if (skin === "UA") {
    //   HTMLTag.setAttribute("data-skin", skin);
    //   localStorage.setItem('skin-mode', skin);

    //   onSkin(skin);

    // }
    else {
      HTMLTag.removeAttribute("data-skin");
      localStorage.removeItem('skin-mode');

      onSkin('');
    }

  };

  const sidebarSkin = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    HTMLTag.removeAttribute("data-sidebar");

    if (skin !== "default") {
      HTMLTag.setAttribute("data-sidebar", skin);
      localStorage.setItem("sidebar-skin", skin);
    } else {
      localStorage.removeItem("sidebar-skin", skin);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      setPantalla4(query);
      setOrigenIn("SE")
      //SetPantalla2({...pantalla2, busqueda: e})
      console.log("EN Header ")
      console.log(query)
      setQuery(query)
      setMostrarWSE(true);
      setMostrarBD(false);
      //setBandera(true);
    }
  }

  return (

    <div className="header-main px-3 px-lg-4 mb-3">
 
      <Link onClick={toggleSidebar} className="menu-link me-10 me-lg-4"><i className="ri-menu-2-fill"></i></Link>
       {/* <NavBarMenu dropdownTitle="charts" SetChart={(e) => {ClickSetChart(e)} } />  */}
       {/* <Link to="/" >  */}
       {/* <NavBarMenu dropdownTitle="Gráficos" SetChart={(e) => {setIdq(e)} } /> */}
       {/* </Link> */}
      <div className="form-search me-auto d-flex h-10 w-50">
        <input type="text" className="form-control"
            placeholder={placeholder}
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            onKeyDown={handleKeyDown} />
        <i className="ri-search-line"></i>
        {/* <ButtonDefaultLight onClick={(e) => {ClickBoton(query)}}> */}
        <Link to="/" > 
        <ButtonDefaultLight onClick={() => ClickBoton(query)}>

         <Buscar>buscar</Buscar> 
      </ButtonDefaultLight>
      </Link> 
      </div>
  
      <Dropdown className="dropdown-skin" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="ri-settings-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <label>Skin</label>
          <nav className="nav nav-skin">
            <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link" : "nav-link active"}>Light</Link>
            <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link active" : "nav-link"}>Dark</Link>
       {/*      <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link" : "nav-link active"}>UA</Link> */}
            {/* <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link active" : "nav-link"}>Experimental</Link> */}
          </nav>
          <hr />
          <label>Sidebar Skin</label>
          <nav id="sidebarSkin" className="nav nav-skin">
            <Link onClick={sidebarSkin} className={!(localStorage.getItem("sidebar-skin")) ? "nav-link active" : "nav-link"}>Default</Link>
            <Link onClick={sidebarSkin} className={(localStorage.getItem("sidebar-skin") === "prime") ? "nav-link active" : "nav-link"}>Prime</Link>
            <Link onClick={sidebarSkin} className={(localStorage.getItem("sidebar-skin") === "dark") ? "nav-link active" : "nav-link"}>Dark</Link>
          </nav>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  
  )
}

const ButtonDefaultLight = styled.div`
  margin-top: 1px;
  display: flex;
  padding: 8.5px 15px;
  align-items: flex-start;
  min-width: 82px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #000000;
  transition: all 0.2s ease;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    transform: translate(0, -5px);
    box-shadow: 0px 12px 5px -8px rgba(0, 0, 0, 0.2);
  }
`;

const Buscar = styled.div`
  min-height: 19px;
  font-family: var(--font-family-helveticaneue-regular);
  font-weight: 400;
  color: var(--blue);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
`;
